// footer max 26rem

.footer {

  .footer_container {

    div {
      width: 100%;
      padding: 2.5rem 1rem;
    }
  }
}